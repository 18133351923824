import React from 'react';

const IconVentilation = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="64px"
			height="64px"
			viewBox="0 0 64 64"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(96.862745%,75.294118%,17.647059%)",
						fillOpacity: 1
					}}
					d="M 32 0 L 29.386719 37.476562 L 32 64 L 57.894531 64 L 57.894531 29.347656 L 61.679688 29.347656 Z M 32 0 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(98.431373%,88.627451%,48.235294%)",
						fillOpacity: 1
					}}
					d="M 2.320312 29.347656 L 6.105469 29.347656 L 6.105469 64 L 32 64 L 32 0 Z M 2.320312 29.347656 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(0%,47.45098%,80.784314%)",
						fillOpacity: 1
					}}
					d="M 32 18.671875 L 28.90625 38.496094 L 32 57.164062 C 42.628906 57.164062 51.246094 48.546875 51.246094 37.917969 C 51.246094 27.289062 42.628906 18.671875 32 18.671875 Z M 32 18.671875 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(15.686275%,56.078431%,85.098039%)",
						fillOpacity: 1
					}}
					d="M 12.753906 37.917969 C 12.753906 48.546875 21.371094 57.164062 32 57.164062 L 32 18.671875 C 21.371094 18.671875 12.753906 27.289062 12.753906 37.917969 Z M 12.753906 37.917969 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(87.45098%,87.45098%,87.45098%)",
						fillOpacity: 1
					}}
					d="M 36.824219 29.875 C 36.824219 27.210938 34.664062 25.050781 32 25.050781 L 30.453125 35.242188 L 35.222656 35.242188 C 36.207031 33.5 36.824219 31.300781 36.824219 29.875 Z M 36.824219 29.875 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(94.509804%,94.509804%,94.509804%)",
						fillOpacity: 1
					}}
					d="M 27.175781 29.875 C 27.175781 31.300781 27.792969 33.5 28.777344 35.242188 L 32 35.242188 L 32 25.050781 C 29.335938 25.050781 27.175781 27.210938 27.175781 29.875 Z M 27.175781 29.875 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(94.509804%,94.509804%,94.509804%)",
						fillOpacity: 1
					}}
					d="M 22.621094 37.761719 C 20.3125 39.09375 19.523438 42.042969 20.855469 44.351562 L 30.457031 40.597656 L 28.070312 36.464844 C 26.070312 36.484375 23.855469 37.050781 22.621094 37.761719 Z M 22.621094 37.761719 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(87.45098%,87.45098%,87.45098%)",
						fillOpacity: 1
					}}
					d="M 27.445312 46.117188 C 28.679688 45.40625 30.277344 43.769531 31.292969 42.046875 L 29.683594 39.257812 L 20.855469 44.351562 C 22.1875 46.660156 25.136719 47.449219 27.445312 46.117188 Z M 27.445312 46.117188 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(87.45098%,87.45098%,87.45098%)",
						fillOpacity: 1
					}}
					d="M 41.378906 37.761719 C 43.6875 39.09375 44.476562 42.042969 43.144531 44.351562 L 33.542969 40.597656 L 35.929688 36.464844 C 37.929688 36.484375 40.144531 37.050781 41.378906 37.761719 Z M 41.378906 37.761719 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(94.509804%,94.509804%,94.509804%)",
						fillOpacity: 1
					}}
					d="M 36.554688 46.117188 C 35.320312 45.40625 33.722656 43.769531 32.707031 42.046875 L 34.316406 39.257812 L 43.144531 44.351562 C 41.8125 46.660156 38.863281 47.449219 36.554688 46.117188 Z M 36.554688 46.117188 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(15.686275%,56.078431%,85.098039%)",
						fillOpacity: 1
					}}
					d="M 32 32.65625 L 28.90625 37.917969 L 32 43.179688 C 34.90625 43.179688 37.261719 40.824219 37.261719 37.917969 C 37.261719 35.011719 34.90625 32.65625 32 32.65625 Z M 32 32.65625 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(54.509804%,79.607843%,94.509804%)",
						fillOpacity: 1
					}}
					d="M 26.738281 37.917969 C 26.738281 40.824219 29.09375 43.179688 32 43.179688 L 32 32.65625 C 29.09375 32.65625 26.738281 35.011719 26.738281 37.917969 Z M 26.738281 37.917969 "
				/>
			</g>
		</svg>

	);
};

export default IconVentilation;