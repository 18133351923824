import React from 'react';

const IconLighting = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="64px"
			height="64px"
			viewBox="0 0 64 64"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(87.843138%,87.843138%,87.843138%)",
						fillOpacity: 1
					}}
					d="M 45.730469 23.078125 L 40.035156 23.078125 C 40.035156 18.660156 36.316406 15.328125 31.964844 15.328125 L 31.964844 9.628906 C 39.773438 9.628906 45.730469 15.589844 45.730469 23.078125 Z M 45.730469 23.078125 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,45.490196%,8.235294%)",
						fillOpacity: 1
					}}
					d="M 31.964844 51.105469 L 36.316406 51.105469 L 36.316406 52.832031 L 31.964844 52.832031 Z M 27.550781 54.625 L 31.964844 54.625 L 31.964844 56.289062 L 27.550781 56.289062 Z M 32.144531 36.800781 C 29.953125 36.800781 25.738281 35.292969 24.515625 34.460938 L 25.5 33.011719 C 26.53125 33.710938 29.515625 34.988281 32.8125 34.988281 C 34.1875 34.839844 36.699219 34.394531 38.671875 33.085938 L 39.640625 34.546875 C 37.914062 35.695312 35.871094 36.25 34.464844 36.515625 C 33.742188 36.652344 32.144531 36.800781 32.144531 36.800781 Z M 32.144531 36.800781 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,45.490196%,8.235294%)",
						fillOpacity: 1
					}}
					d="M 31.132812 35.746094 L 32.796875 35.746094 L 32.796875 39.203125 L 31.132812 39.203125 Z M 31.132812 35.746094 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(14.901961%,14.901961%,14.901961%)",
						fillOpacity: 1
					}}
					d="M 39.773438 46.003906 L 39.773438 40.808594 C 46.746094 37.746094 51.296875 30.871094 51.296875 23.214844 C 51.296875 12.582031 42.710938 3.933594 32.078125 3.933594 C 21.445312 3.933594 12.683594 12.582031 12.683594 23.214844 C 12.683594 30.871094 17.4375 37.746094 23.964844 40.808594 L 23.964844 46.003906 C 23.964844 47.636719 25.4375 49.003906 26.71875 49.394531 L 26.71875 56.523438 C 26.71875 58.457031 28.457031 59.871094 30.390625 59.871094 L 33.898438 59.871094 C 35.832031 59.871094 37.148438 58.457031 37.148438 56.523438 L 37.148438 49.394531 C 38.941406 49.003906 39.773438 47.636719 39.773438 46.003906 Z M 33.898438 58.082031 L 30.390625 58.082031 C 29.425781 58.082031 28.382812 57.492188 28.382812 56.523438 L 28.382812 49.3125 L 35.355469 49.3125 L 35.355469 56.523438 C 35.355469 57.492188 34.863281 58.082031 33.898438 58.082031 Z M 36.527344 47.648438 L 27.761719 47.648438 C 26.796875 47.648438 25.757812 46.972656 25.757812 46.003906 L 25.757812 41.496094 C 26.71875 41.714844 27.203125 41.90625 27.886719 42.054688 C 28.359375 42.160156 28.890625 41.859375 28.996094 41.386719 C 29.097656 40.914062 28.828125 40.445312 28.355469 40.34375 C 20.375 38.601562 14.597656 31.398438 14.597656 23.214844 C 14.597656 13.550781 22.472656 5.6875 32.136719 5.6875 C 41.804688 5.6875 49.671875 13.550781 49.671875 23.214844 C 49.671875 31.398438 43.882812 38.601562 35.902344 40.34375 C 35.425781 40.445312 35.128906 40.914062 35.230469 41.386719 C 35.320312 41.796875 35.683594 42.074219 36.089844 42.074219 C 36.148438 42.074219 36.128906 42.070312 36.191406 42.054688 C 36.875 41.90625 37.660156 41.714844 38.109375 41.496094 L 38.109375 46.003906 C 38.109375 46.972656 37.496094 47.648438 36.527344 47.648438 Z M 36.527344 47.648438 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(14.901961%,14.901961%,14.901961%)",
						fillOpacity: 1
					}}
					d="M 39.980469 23.910156 L 43.945312 23.910156 C 43.796875 26.148438 43.136719 27.957031 42.015625 29.652344 L 43.34375 30.667969 C 44.820312 28.441406 45.46875 25.894531 45.46875 23.214844 L 45.46875 22.117188 L 39.980469 22.117188 C 39.542969 18.214844 36.199219 15.105469 32.144531 15.105469 C 28.09375 15.105469 24.746094 18.214844 24.308594 22.117188 L 18.398438 22.117188 L 18.398438 23.214844 C 18.398438 25.894531 19.257812 28.488281 20.730469 30.710938 L 22.230469 29.648438 C 21.109375 27.957031 20.492188 26.148438 20.34375 23.910156 L 24.308594 23.910156 C 24.746094 27.8125 28.09375 30.921875 32.144531 30.921875 C 36.199219 30.921875 39.542969 27.8125 39.980469 23.910156 Z M 32.144531 29.351562 C 28.761719 29.351562 26.007812 26.597656 26.007812 23.214844 C 26.007812 19.832031 28.761719 17.082031 32.144531 17.082031 C 35.527344 17.082031 38.28125 19.832031 38.28125 23.214844 C 38.28125 26.597656 35.527344 29.351562 32.144531 29.351562 Z M 32.144531 29.351562 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(14.901961%,14.901961%,14.901961%)",
						fillOpacity: 1
					}}
					d="M 22.246094 34.355469 L 21.007812 33.113281 L 24.074219 30.046875 L 25.3125 31.285156 Z M 42.042969 34.355469 L 38.976562 31.285156 L 40.214844 30.046875 L 43.28125 33.113281 Z M 19.230469 51.265625 L 19.230469 41.953125 L 17.4375 41.953125 L 17.4375 51.265625 C 17.4375 53.199219 19.253906 54.625 21.1875 54.625 L 23.964844 54.625 L 23.964844 52.832031 L 21.1875 52.832031 C 20.222656 52.832031 19.230469 52.230469 19.230469 51.265625 Z M 44.636719 51.265625 C 44.636719 52.230469 44.070312 52.832031 43.101562 52.832031 L 39.773438 52.832031 L 39.773438 54.625 L 43.101562 54.625 C 45.035156 54.625 46.425781 53.199219 46.425781 51.265625 L 46.425781 41.953125 L 44.636719 41.953125 Z M 32.144531 19.710938 C 30.210938 19.710938 28.640625 21.28125 28.640625 23.214844 C 28.640625 25.148438 30.210938 26.722656 32.144531 26.722656 C 34.078125 26.722656 35.652344 25.148438 35.652344 23.214844 C 35.652344 21.28125 34.078125 19.710938 32.144531 19.710938 Z M 32.144531 24.96875 C 31.179688 24.96875 30.390625 24.183594 30.390625 23.214844 C 30.390625 22.25 31.179688 21.464844 32.144531 21.464844 C 33.113281 21.464844 33.898438 22.25 33.898438 23.214844 C 33.898438 24.183594 33.113281 24.96875 32.144531 24.96875 Z M 32.144531 24.96875 "
				/>
			</g>
		</svg>

	);
};

export default IconLighting;