import React from 'react';

const IconWater = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="64px"
			height="64px"
			viewBox="0 0 64 64"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(69.411765%,77.647059%,89.411765%)",
						fillOpacity: 1
					}}
					d="M 57.738281 29.914062 L 49.390625 29.914062 C 48.238281 29.914062 47.304688 30.847656 47.304688 32 L 47.304688 37.476562 C 47.304688 40.210938 49.527344 42.433594 52.261719 42.433594 L 54.871094 42.433594 C 57.601562 42.433594 59.824219 40.210938 59.824219 37.476562 L 59.824219 32 C 59.824219 30.847656 58.890625 29.914062 57.738281 29.914062 Z M 57.738281 29.914062 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(61.568627%,69.019608%,79.215686%)",
						fillOpacity: 1
					}}
					d="M 57.738281 29.914062 L 53.566406 29.914062 L 53.566406 42.433594 L 54.871094 42.433594 C 57.601562 42.433594 59.824219 40.210938 59.824219 37.476562 L 59.824219 32 C 59.824219 30.847656 58.890625 29.914062 57.738281 29.914062 Z M 57.738281 29.914062 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(77.254902%,86.27451%,99.215686%)",
						fillOpacity: 1
					}}
					d="M 57.929688 17.390625 L 48.496094 17.40625 C 46.023438 17.410156 43.613281 17.890625 41.378906 18.820312 L 22.207031 26.195312 L 22.957031 50.78125 C 23.390625 50.78125 23.828125 50.648438 24.203125 50.371094 L 44.234375 35.460938 C 45.339844 34.574219 46.730469 34.085938 48.144531 34.085938 L 58.285156 34.085938 C 61.4375 34.085938 64 31.523438 64 28.371094 L 64 23.453125 C 64 20.148438 61.347656 17.402344 57.929688 17.390625 Z M 57.929688 17.390625 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(69.411765%,77.647059%,89.411765%)",
						fillOpacity: 1
					}}
					d="M 57.929688 17.390625 L 53.566406 17.398438 L 53.566406 34.085938 L 58.285156 34.085938 C 61.4375 34.085938 64 31.523438 64 28.371094 L 64 23.453125 C 64 20.148438 61.347656 17.402344 57.929688 17.390625 Z M 57.929688 17.390625 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(69.411765%,77.647059%,89.411765%)",
						fillOpacity: 1
					}}
					d="M 25.042969 61.21875 C 25.042969 62.371094 24.109375 63.304688 22.957031 63.304688 L 2.085938 63.304688 C 0.933594 63.304688 0 62.371094 0 61.21875 L 0 15.304688 L 22.957031 15.304688 C 24.109375 15.304688 25.042969 16.238281 25.042969 17.390625 Z M 25.042969 61.21875 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(85.882353%,91.372549%,99.215686%)",
						fillOpacity: 1
					}}
					d="M 10.433594 54.957031 C 9.28125 54.957031 8.347656 54.023438 8.347656 52.871094 L 8.347656 25.738281 C 8.347656 24.585938 9.28125 23.652344 10.433594 23.652344 C 11.585938 23.652344 12.523438 24.585938 12.523438 25.738281 L 12.523438 52.871094 C 12.523438 54.023438 11.585938 54.957031 10.433594 54.957031 Z M 10.433594 54.957031 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(56.078431%,94.117647%,94.117647%)",
						fillOpacity: 1
					}}
					d="M 53.566406 63.304688 C 48.960938 63.304688 45.21875 59.558594 45.21875 54.957031 C 45.21875 50.90625 50.878906 44.394531 52.011719 43.128906 C 52.804688 42.246094 54.328125 42.246094 55.117188 43.128906 C 56.253906 44.394531 61.914062 50.90625 61.914062 54.957031 C 61.914062 59.558594 58.167969 63.304688 53.566406 63.304688 Z M 53.566406 63.304688 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(52.941176%,84.313725%,84.313725%)",
						fillOpacity: 1
					}}
					d="M 61.914062 54.957031 C 61.914062 50.90625 56.253906 44.394531 55.117188 43.128906 C 54.722656 42.6875 54.144531 42.464844 53.566406 42.464844 L 53.566406 63.304688 C 58.167969 63.304688 61.914062 59.558594 61.914062 54.957031 Z M 61.914062 54.957031 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(77.254902%,86.27451%,99.215686%)",
						fillOpacity: 1
					}}
					d="M 45.914062 6.957031 C 45.914062 9.699219 44.078125 12.160156 41.460938 12.953125 L 25.085938 19.476562 L 12.523438 19.535156 L 2.085938 19.476562 L 0 19.476562 L 0 14.332031 C 0 9.070312 3.921875 4.617188 9.140625 3.964844 C 9.167969 3.964844 10.476562 3.824219 12.523438 3.601562 C 20.4375 2.753906 39.511719 0.695312 39.652344 0.695312 C 43.101562 0.695312 45.914062 3.507812 45.914062 6.957031 Z M 45.914062 6.957031 "
				/>
			</g>
		</svg>

	);
};

export default IconWater;