import React from 'react';

const IconBuilding = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="64px"
			height="64px"
			viewBox="0 0 64 64"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(93.333333%,92.941176%,94.901961%)",
						fillOpacity: 1
					}}
					d="M 58.914062 30.476562 C 58.914062 45.359375 46.847656 57.425781 31.960938 57.425781 C 17.078125 57.425781 5.011719 45.359375 5.011719 30.476562 C 5.011719 15.589844 17.078125 3.523438 31.960938 3.523438 C 46.847656 3.523438 58.914062 15.589844 58.914062 30.476562 Z M 58.914062 30.476562 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(82.745098%,83.529412%,90.588235%)",
						fillOpacity: 1
					}}
					d="M 48.585938 28.925781 L 47.675781 28.925781 L 47.675781 57.4375 L 19.164062 57.4375 L 19.164062 28.925781 L 18.25 28.925781 C 18.125 28.925781 18.023438 28.800781 18.0625 28.675781 L 22.3125 15.738281 C 22.4375 15.375 22.773438 15.125 23.164062 15.125 L 43.664062 15.125 C 44.050781 15.125 44.386719 15.375 44.511719 15.738281 L 48.773438 28.675781 C 48.8125 28.800781 48.726562 28.925781 48.585938 28.925781 Z M 48.585938 28.925781 "
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 399 459.40625 L 471.40625 459.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 115.09375 459.40625 L 386.5 459.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 40.09375 459.40625 L 101.09375 459.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 190.1875 192.8125 C 190.1875 203.59375 181.40625 212.40625 170.59375 212.40625 L 90.5 212.40625 C 81.90625 212.40625 75 205.5 75 196.90625 C 75 196.40625 75 195.90625 75.09375 195.3125 C 75.09375 194.90625 75.1875 194.59375 75.1875 194.1875 C 76.3125 188 81 183 87.1875 181.6875 C 88.3125 181.5 89.40625 181.3125 90.5 181.3125 C 90.59375 181.3125 90.59375 181.3125 90.6875 181.3125 C 90.59375 180.40625 90.5 179.59375 90.5 178.6875 C 90.5 177.3125 90.59375 175.90625 90.90625 174.5 C 92.8125 164.59375 101.5 157.1875 111.90625 157.1875 C 113.3125 157.1875 114.59375 157.3125 115.90625 157.59375 C 118.90625 148.5 126 141.1875 135 138 C 137.8125 137 140.8125 136.40625 143.90625 136.1875 C 144.40625 136.1875 144.90625 136.1875 145.40625 136.1875 C 145.5 136.1875 145.5 136.1875 145.59375 136.1875 C 162.59375 136.3125 176.40625 150.1875 176.40625 167.1875 C 176.40625 169.5 176.1875 171.6875 175.6875 173.8125 C 184.09375 176.1875 190.1875 183.8125 190.1875 192.8125 Z M 190.1875 192.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 176.40625 166.90625 C 176.40625 175.8125 172.6875 183.8125 166.6875 189.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 111.3125 157.1875 C 121.09375 157.1875 129.40625 164 131.59375 173.09375 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 440.8125 272.59375 C 440.8125 283.40625 432 292.1875 421.1875 292.1875 L 341.09375 292.1875 C 332.5 292.1875 325.59375 285.3125 325.59375 276.6875 C 325.59375 276.1875 325.59375 275.6875 325.6875 275.09375 C 325.6875 274.6875 325.8125 274.40625 325.8125 274 C 326.90625 267.8125 331.59375 262.8125 337.8125 261.5 C 338.90625 261.3125 340 261.09375 341.09375 261.09375 C 341.1875 261.09375 341.1875 261.09375 341.3125 261.09375 C 341.1875 260.1875 341.09375 259.40625 341.09375 258.5 C 341.09375 257.09375 341.1875 255.6875 341.5 254.3125 C 343.40625 244.40625 352.09375 237 362.5 237 C 363.90625 237 365.1875 237.09375 366.5 237.40625 C 369.5 228.3125 376.59375 221 385.59375 217.8125 C 388.40625 216.8125 391.40625 216.1875 394.5 216 C 395 216 395.5 216 396 216 C 396.09375 216 396.09375 216 396.1875 216 C 413.1875 216.09375 427 230 427 247 C 427 249.3125 426.8125 251.5 426.3125 253.59375 C 434.6875 256 440.8125 263.59375 440.8125 272.59375 Z M 440.8125 272.59375 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 427 246.6875 C 427 255.59375 423.3125 263.59375 417.3125 269.3125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 361.90625 237 C 371.6875 237 380 243.8125 382.1875 252.90625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(41.176471%,68.235294%,97.254902%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 436.5 353.8125 C 437.5 354.90625 438.40625 356 439.3125 357.1875 C 441.09375 359.6875 442.59375 362.09375 444.1875 364.5 C 450.6875 374.1875 458 385.90625 460.8125 398 C 461.59375 401.3125 462 404.6875 462.09375 407.90625 C 461.59375 424.90625 447.6875 438.5 430.59375 438.5 C 413.5 438.5 399.59375 424.90625 399.09375 407.90625 C 399.09375 404.6875 399.59375 401.3125 400.40625 398 C 403.1875 385.90625 410.3125 374.1875 416.6875 364.5 C 418.40625 362 420.3125 359.5 422.1875 356.90625 C 423 355.6875 423.90625 354.59375 424.90625 353.59375 L 426 352.40625 C 428.5 349.59375 432.8125 349.6875 435.3125 352.40625 Z M 436.5 353.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 430.5 388.8125 L 430.5 459.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(82.352941%,83.137255%,90.196078%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 430.5 424.59375 L 414.90625 406.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(82.352941%,83.137255%,90.196078%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 430.5 406.90625 L 440.59375 395.3125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(47.45098%,79.215686%,63.137255%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 94.90625 353.8125 C 95.90625 354.90625 96.8125 356 97.6875 357.1875 C 99.5 359.6875 101 362.09375 102.59375 364.5 C 109.09375 374.1875 116.40625 385.90625 119.1875 398 C 120 401.3125 120.40625 404.6875 120.5 407.90625 C 120 424.90625 106.09375 438.5 89 438.5 C 71.90625 438.5 58 424.90625 57.5 407.90625 C 57.5 404.6875 58 401.3125 58.8125 398 C 61.59375 385.90625 68.6875 374.1875 75.09375 364.5 C 76.8125 362 78.6875 359.5 80.59375 356.90625 C 81.40625 355.6875 82.3125 354.59375 83.3125 353.59375 L 84.40625 352.40625 C 86.90625 349.59375 91.1875 349.6875 93.6875 352.40625 Z M 94.90625 353.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 88.90625 388.8125 L 88.90625 459.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(82.352941%,83.137255%,90.196078%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 88.90625 424.59375 L 73.3125 406.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(82.352941%,83.137255%,90.196078%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 88.90625 406.90625 L 99 395.3125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 140 231 L 368.09375 231 L 368.09375 459.09375 L 140 459.09375 Z M 140 231 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 335.40625 429.8125 L 304.1875 429.8125 C 302.40625 429.8125 300.90625 428.3125 300.90625 426.5 L 300.90625 366.1875 C 300.90625 364.40625 302.40625 362.90625 304.1875 362.90625 L 335.40625 362.90625 C 337.1875 362.90625 338.6875 364.40625 338.6875 366.1875 L 338.6875 426.5 C 338.6875 428.3125 337.1875 429.8125 335.40625 429.8125 Z M 335.40625 429.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 300.90625 386.5 L 338.6875 386.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 294.3125 429.8125 L 345.3125 429.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 37.9375 50.375 L 37.9375 50.773438 L 40.5 48.636719 L 40.0625 48.636719 Z M 37.9375 50.375 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 37.9375 51.074219 L 37.9375 51.8125 L 41.8125 48.636719 L 41.074219 48.636719 Z M 37.9375 51.074219 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 41.273438 47.988281 L 42.011719 47.386719 L 42.011719 47.011719 L 40.8125 47.988281 Z M 41.273438 47.988281 "
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 268.1875 429.8125 L 237 429.8125 C 235.1875 429.8125 233.6875 428.3125 233.6875 426.5 L 233.6875 366.1875 C 233.6875 364.40625 235.1875 362.90625 237 362.90625 L 268.1875 362.90625 C 270 362.90625 271.5 364.40625 271.5 366.1875 L 271.5 426.5 C 271.5 428.3125 270 429.8125 268.1875 429.8125 Z M 268.1875 429.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 233.6875 386.5 L 271.5 386.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 227.09375 429.8125 L 278.09375 429.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 29.539062 50.375 L 29.539062 50.773438 L 32.113281 48.648438 L 31.675781 48.648438 Z M 29.539062 50.375 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 29.539062 51.074219 L 29.539062 51.8125 L 33.414062 48.648438 L 32.6875 48.648438 Z M 29.539062 51.074219 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 32.886719 47.988281 L 33.613281 47.386719 L 33.625 47.011719 L 32.425781 48 Z M 32.886719 47.988281 "
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 336.90625 321.40625 L 305.6875 321.40625 C 303.90625 321.40625 302.40625 319.90625 302.40625 318.09375 L 302.40625 257.8125 C 302.40625 256 303.90625 254.5 305.6875 254.5 L 336.90625 254.5 C 338.6875 254.5 340.1875 256 340.1875 257.8125 L 340.1875 318.09375 C 340.1875 319.90625 338.6875 321.40625 336.90625 321.40625 Z M 336.90625 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 302.40625 279.09375 L 340.1875 279.09375 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 295.6875 321.40625 L 346.8125 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 38.125 36.949219 L 38.125 37.351562 L 40.6875 35.210938 L 40.25 35.210938 Z M 38.125 36.949219 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 38.125 37.648438 L 38.125 38.386719 L 42 35.210938 L 41.273438 35.210938 Z M 38.125 37.648438 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 41.460938 34.5625 L 42.1875 33.960938 L 42.1875 33.601562 L 41 34.5625 Z M 41.460938 34.5625 "
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 269.6875 321.40625 L 238.5 321.40625 C 236.6875 321.40625 235.1875 319.90625 235.1875 318.09375 L 235.1875 257.8125 C 235.1875 256 236.6875 254.5 238.5 254.5 L 269.6875 254.5 C 271.5 254.5 273 256 273 257.8125 L 273 318.09375 C 273 319.90625 271.5 321.40625 269.6875 321.40625 Z M 269.6875 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 235.1875 279.90625 L 273 279.90625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 228.5 321.40625 L 279.59375 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 29.726562 37.039062 L 29.726562 37.4375 L 32.289062 35.3125 L 31.851562 35.3125 Z M 29.726562 37.039062 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 29.726562 37.738281 L 29.726562 38.476562 L 33.601562 35.3125 L 32.875 35.3125 Z M 29.726562 37.738281 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 33.074219 34.664062 L 33.800781 34.050781 L 33.8125 33.6875 L 32.601562 34.664062 Z M 33.074219 34.664062 "
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 202.5 321.40625 L 171.3125 321.40625 C 169.5 321.40625 168 319.90625 168 318.09375 L 168 257.8125 C 168 256 169.5 254.5 171.3125 254.5 L 202.5 254.5 C 204.3125 254.5 205.8125 256 205.8125 257.8125 L 205.8125 318.09375 C 205.8125 319.90625 204.3125 321.40625 202.5 321.40625 Z M 202.5 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 168 279.90625 L 205.8125 279.90625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 161.3125 321.40625 L 212.40625 321.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 21.324219 37.039062 L 21.324219 37.4375 L 23.886719 35.3125 L 23.449219 35.3125 Z M 21.324219 37.039062 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 21.324219 37.738281 L 21.324219 38.476562 L 25.199219 35.3125 L 24.476562 35.3125 Z M 21.324219 37.738281 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 24.675781 34.664062 L 25.398438 34.050781 L 25.414062 33.6875 L 24.199219 34.664062 Z M 24.675781 34.664062 "
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(94.117647%,76.470588%,18.823529%)",
						fillOpacity: 1,
						strokeWidth: 6,
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 368.09375 443 L 140 443 L 140 459.1875 L 368.09375 459.1875 Z M 368.09375 443 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 334.90625 345.5 L 368.59375 345.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 319 345.5 L 326.5 345.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 208.5 459.40625 L 165.3125 459.40625 C 164 459.40625 162.90625 458.3125 162.90625 457 L 162.90625 365.40625 C 162.90625 364.09375 164 363 165.3125 363 L 208.5 363 C 209.8125 363 210.90625 364.09375 210.90625 365.40625 L 210.90625 457 C 210.90625 458.40625 209.8125 459.40625 208.5 459.40625 Z M 208.5 459.40625 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(11.764706%,14.117647%,49.411765%)",
						fillOpacity: 1
					}}
					d="M 24.761719 51.375 C 25.125 51.375 25.125 50.8125 24.761719 50.8125 C 24.398438 50.8125 24.398438 51.375 24.761719 51.375 Z M 24.761719 51.375 "
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 198.09375 451.09375 L 201.90625 451.09375 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 179.8125 451.09375 L 193.09375 451.09375 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.490196%,52.54902%,49.019608%)",
						fillOpacity: 1,
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 375.5 231 L 132.6875 231 C 131.6875 231 130.90625 230 131.1875 229 L 165.3125 125.5 C 166.3125 122.59375 169 120.59375 172.09375 120.59375 L 336.09375 120.59375 C 339.1875 120.59375 341.90625 122.59375 342.90625 125.5 L 376.90625 229 C 377.1875 230 376.5 231 375.5 231 Z M 375.5 231 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(85.882353%,90.588235%,99.607843%)",
						fillOpacity: 1,
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 266.6875 203.1875 L 241.5 203.1875 C 239.59375 203.1875 238.09375 201.6875 238.09375 199.8125 L 238.09375 161 C 238.09375 159.09375 239.59375 157.59375 241.5 157.59375 L 266.6875 157.59375 C 268.59375 157.59375 270.09375 159.09375 270.09375 161 L 270.09375 199.8125 C 270.09375 201.59375 268.5 203.1875 266.6875 203.1875 Z M 266.6875 203.1875 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 238.09375 174 L 270.09375 174 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 5,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 231.40625 203.1875 L 276.8125 203.1875 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 30.085938 23.824219 L 30.085938 24.5625 L 33.148438 22.074219 L 32.324219 22.074219 Z M 30.085938 23.824219 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 30.085938 23.113281 L 30.085938 23.523438 L 31.886719 22.074219 L 31.425781 22.074219 Z M 30.085938 23.113281 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 32.636719 21.414062 L 33.414062 20.800781 L 33.414062 20.375 L 32.101562 21.414062 Z M 32.636719 21.414062 "
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 328 130.3125 L 330.59375 130.3125 C 332.09375 130.3125 333.5 131.3125 334 132.8125 L 334.59375 134.6875 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fill: "none",
						strokeWidth: 3,
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 179 128.09375 L 176.40625 128.09375 C 174.90625 128.09375 173.5 129.09375 173 130.59375 L 172.40625 132.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(41.176471%,67.843137%,96.862745%)",
						fillOpacity: 1,
						strokeWidth: "2.5",
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 184.3125 176.40625 L 190.90625 181.5 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(41.176471%,67.843137%,96.862745%)",
						fillOpacity: 1,
						strokeWidth: "2.5",
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 184.3125 170.1875 L 197.40625 180.1875 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
				<path
					style={{
						fillRule: "nonzero",
						fill: "rgb(41.176471%,67.843137%,96.862745%)",
						fillOpacity: 1,
						strokeWidth: "2.5",
						strokeLinecap: "round",
						strokeLinejoin: "miter",
						stroke: "rgb(11.764706%,14.117647%,49.411765%)",
						strokeOpacity: 1,
						strokeMiterlimit: 10
					}}
					d="M 193.8125 171 L 197.40625 173.8125 "
					transform="matrix(0.125,0,0,0.125,0,0)"
				/>
			</g>
		</svg>
	);
};

export default IconBuilding;