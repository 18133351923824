import React from "react";
import IconWater from "./icons/IconWater";
import IconHouse from "./icons/IconHouse";
import IconBuilding from "./icons/IconBuilding";
import IconDrip from "./icons/IconDrip";
import IconVentilation from "./icons/IconVentilation";
import IconLighting from "./icons/IconLighting";

export const Services = () => {
  const data =   [
    {
      icon: <IconBuilding/>,
      name: "Բնակելի. հասարակական և արտադրական շենք-շինությունների նախագծում"
    },
    {
      icon: <IconVentilation/>,
      name: "Ջեռուցման, օդափոխության, հովացման, սառնամատակարարման համակարգերի նախագծում"
    },
    {
      icon: <IconWater/>,
      name: "Ջրամատակարարման և ջրահեռացման ներքին և արտաքին, ոռոգման համակարգերի նախագծում"
    },
    {
      icon: <IconLighting/>,
      name: "Էլեկտրամատակարարման և լուսավորության ներքին և արտաքին ցանցերի և հակահրդեհային ահազանգման ցանցի նախագծում"
    }
  ];
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Մեր ծառայությունները</h2>
        </div>
        <div className="row">
          {data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 service">
                  {" "}
                  <div className='d-flex justify-center'>
                    <div className='icon'>
                      {d.icon}
                    </div>
                  </div>
                  <div className="service-desc">
                  <h3>{d.name}</h3>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
